.timeline-container {
  /* background-color: black; */
  display: flex;
  flex-direction: column;
  position: relative;
  top: 5%;
  margin: 40px 40px;
}

.timeline-container::after {
  background-color: #e17b77;
  content: "";
  position: absolute;
  width: 4px;
  left: 5%;
  height: 100%;
}

.timeline-item {
  /* background-color: blue; */
  display: flex;
  justify-content: flex-end;
  position: relative;
  left: 10%;
  width: 100%;
  margin: 0 40px 0 0;
  padding: 10px;
}

.timeline-item-content {
  box-shadow: -2px 3px 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 15px 15px 15px;
  position: relative;
  width: 90%;
  text-align: left;
}

.timeline-item-content::after {
  content: " ";
  background-color: #fff;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -7.5px;
  top: calc(50% - 7.5px);
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
}

.timeline-item-content time {
  color: #777;
  position: absolute;
  bottom: 16px;
  right: 20px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.timeline-item-content p {
  /* background-color: aqua; */
  font-size: 16px;
  line-height: 24px;
  margin: 15px 0;
  width: 100%;
}

.timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #e17b77;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 20px);
  left: -30%;
  width: 40px;
  height: 40px;
  z-index: 100;
}