input {
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid black;
}

label {
  width: 125px;
}

.headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
  width: 600px;
  margin-right: 50px;
}

textarea {
  width: 330px;
  height: 80px;
  border-radius: 10px;
  border: 1px solid black;
  padding: 15px;
}

.textAreaContainer * {
  vertical-align: middle;
}

.fields {
  margin-left: 3vw;
}

.preview-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 40vw;
}

.container {
  display: block;
  position: relative;
  height: 100vh;
  width: 40vw;
  overflow: hidden;
  border: 2px solid black;
  border-radius: 30px 30px 30px 30px;
}

.content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}