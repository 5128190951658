.preview {
  position: relative;
  z-index: 2;
}

.tabs {
  border: 1px solid black;
  color: black;
  background-color: whitesmoke;
}

.notifications {
  margin-left: 3vw;
}

.button {
  position: absolute;
  left: 20%;
}

.tpla-detail-page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.tpla-detail-page:focus {
  outline: none;
}

.split-left {
  /* float: left; */
  width: 40vw;
  margin-left: 2vw;
}

.split-right {
  position: relative;
  /* float: left; */
  margin-top: 18vh;
  width: 40vw;
  height: 40vh;
  margin-left: 4vw;
}

.headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px;
}

.sidepanel {
  width: 0;
  position: fixed;
  z-index: 3;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: whitesmoke;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 1vh;
}

.closebtn {
  position: absolute;
  color: whitesmoke;
  background-color: #e17b77;
  top: 10px;
  left: 0px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  padding: 0;
}

.closebtn:hover {
  color: #e17b77;
  background-color: whitesmoke;
}

.closebtn > svg {
  width: 22px;
  height: 22px;
}
.basic_details {
  color: black;
  background-color: violet;
}

.loan_details {
  color: black;
  background-color: orange;
}

.professional_details {
  color: black;
  background-color: lightgreen;
}

.address_details {
  color: black;
  background-color: lightskyblue;
}

.create_notifications {
  color: black;
  background-color: lightgreen;
}

.floating-button {
  position: fixed;
  /* width: 60px; */
  /* height: 60px; */
  width: 40px;
  height: 40px;
  bottom: 40px;
  right: 20px;
  background-color: #0c9;
  color: whitesmoke;
  border-radius: 50%;
  text-align: center;
  border-color: #0c9;
  font-size: 9px;
}

/*  PDF container CSS starts */
.pdf-container {
  max-width: 650px;
  display: inline-block;
  border: 1px solid #b3b1b1;
  border-radius: 4px;
  box-shadow: 0px 0px 3px 1px #6394d2;
  max-height: 650px;
  overflow-y: auto;
}
/*  PDF container CSS ends */

/* Comment box CSS starts */
.floating-button.notes {
  background-color: rgb(221, 226, 225);
  font-size: 14px;
  border-color: rgb(58, 58, 58);
  width: 40px;
  height: 40px;
  color: #444;
  transition: all 0.2s ease-in-out;
  bottom: 125px;
  outline: none;
}
.floating-button.notes:hover {
  background-color: #fff;
  /* color: whitesmoke; */
  border-color: rgb(58, 58, 58);
  color: #222;
}

.floating-button.notes::after {
  content: none;
}

.floating-button::after {
  background-color: rgb(209, 37, 37);
  content: attr(unread-notifications);
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 30px;
  border-radius: 50%;
  left: 23px;
  visibility: visible;
}

.floating-button:hover {
  background-color: whitesmoke;
  color: #0c9;
}

#notespanel {
  background-color: #f4f4f4;
}

#notespanel::-webkit-scrollbar {
  width: 0.2em;
}

#notespanel::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(175, 175, 175, 0.3);
}

#notespanel::-webkit-scrollbar-thumb {
  background-color: rgb(102, 102, 102);
  outline: 1px solid slategrey;
}
#notespanel .closebtn {
  width: 30px;
  height: 30px;
  outline: none;
  padding: 0;
  left: 0;
  top: 20px;
}
#notespanel .closebtn .react-icons {
  vertical-align: 3px;
}

.notes-inner h3 {
  text-align: center;
}

.notes-inner .notes-input {
  margin-top: 25px;
  text-align: center;
  padding: 5px;
}
.notes-inner .notes-input textarea {
  outline: none;
  font-size: 16px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: border 0.2s ease-in-out;
  box-sizing: border-box;
  padding: 10px;
}
.notes-inner .notes-input textarea:focus {
  border: 2px solid #429dd2;
}
.notes-inner .notes-input button.save_notes {
  margin-top: 20px;
  padding: 5px 30px;
  background-color: #4f98d6;
  color: #fff;
  border-radius: 4px;
  outline: none;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 12px;
}

.notes-inner .notes-input button.save_notes.disabled {
  pointer-events: none;
  background-color: grey;
  cursor: not-allowed;
}

.notes-inner .notes-timeline {
  margin-top: 20px;
  padding: 10px;
}
.notes-inner .notes-timeline > label {
  font-size: 16px;
  border-bottom: 1px solid;
  width: auto;
  padding: 0 10px;
}
.notes-inner .notes-timeline .notes-list {
  background-color: #fff;
  padding: 0 13px;
  border-radius: 6px;
}
.notes-inner .notes-timeline .notes-list .notes-data {
  margin: 20px 0;
  padding: 10px 0 30px 0;
  border-bottom: 1px solid #e7e7e7;
}
.notes-inner .notes-timeline .notes-list .notes-data.highlight {
  border: 1px solid #b1dfff;
  background-color: #ffff0047;
  border-radius: 4px;
}
.notes-inner .notes-timeline .notes-list .notes-data .notes-content {
  font-size: 13px;
}
.notes-inner .notes-timeline .notes-list .notes-data .notes-meta {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 11px;
  color: #7a7a7a;
}
.notes-inner .notes-timeline .notes-list .notes-data .notes-meta i {
  padding: 5px 4px 6px 6px;
  background: #f4f4f4;
  border-radius: 50%;
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;
  font-size: 12px;
  border: 2px solid #bebebe;
  margin-right: 11px;
}
.notes-inner .notes-timeline .notes-list .notes-data .notes-meta > span {
  text-transform: capitalize;
  margin-right: 6px;
  font-weight: bold;
  font-size: 11px;
}
.notes-inner .notes-timeline .notes-list .notes-data .notes-meta time {
  font-weight: bold;
  margin-left: 6px;
}

/* notes box CSS ends */

.whatsapp-container {
  width: 40px;
  height: 40px;
  background-image: url('../assets/whatsapp-logo.png');
  background-size: cover;
  float: left;
}

.whatsapp-text {
  z-index: 1;
  color: green;
  padding-top: 15px;
  font-size: 18px;
  line-height: 100%;
  float: right;
}

.whatsapp-text:hover {
  color: #32cd32;
}

.whatsapp-block {
  max-width: 100%;
  display: inline-block;
}

/* TPLA insights css starts */
.insights-main {
  float: left;
  padding: 15px;
  width: 100%;
}
.insights-main .insights-inner {
}
.insights-main .insights-inner ul li > i {
  margin-right: 9px;
  color: #444;
}
.insights-main .insights-inner .insights-tabbed-data {
  border: 1px solid #444;
  border-radius: 4px;
  line-height: 1.5;
}

.insights-main .insights-inner .insights-tabbed-data div.common_data {
  margin: 12px;
  border-bottom: 5px solid #8090a0;
  padding: 12px 6px;
  display: flex;
  flex-direction: row;
}

.insights-main .insights-inner .insights-tabbed-data div.common_data p {
  margin: 0 20px;
  font-weight: bold;
}
.insights-main .insights-inner .insights-tabbed-data .react-tabs__tab {
  padding: 6px 10px;
}
.insights-main .insights-inner .insights-tabbed-data .react-tabs__tab-panel {
  padding: 0 8px;
}
.insights-main .insights-inner .insights-tabbed-data .insights-data > table {
  border-collapse: separate;
  border-spacing: 0 10px;
}
.insights-main
  .insights-inner
  .insights-tabbed-data
  .insights-data
  > table
  tr
  td.message {
  display: block;
  padding: 10px;
  text-align: center;
  font-weight: bold;
  color: #565656;
  max-width: none;
}
.insights-main
  .insights-inner
  .insights-tabbed-data
  .insights-data
  > table
  td.key {
  padding: 6px 0 6px 4px;
  max-width: 230px;
  min-width: 150px;
  line-height: 20px;
  background-color: #f0f8ffad;
  border-radius: 3px;
}
.insights-main
  .insights-inner
  .insights-tabbed-data
  .insights-data
  > table
  td
  > span.key {
  margin-right: 10px;
  color: #5a5a5a;
  color: #5582d6;
}
.insights-main
  .insights-inner
  .insights-tabbed-data
  .insights-data
  > table
  td
  > span.value {
  margin-left: 10px;
  font-weight: bold;
  color: #5a5a5a;
}

.insights-main
  .insights-inner
  .insights-tabbed-data
  .insights-data
  > table
  td
  > code {
  color: #444;
  background: #f9f9f9;
  font-size: 13px;
  font-weight: bold;
}
/* TPLA insights css ends */
