.export_button {
  color: #008000;
}

.tpla_page {
  width: 95vw;
  height: 95vh;
  margin-left: 1vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.form-group {
  margin-bottom: 20px;
}
.form-label {
  margin-bottom: 5px;
}
.form-group-reverse {
  margin-bottom: 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: baseline;
}
.form-group input[type='checkbox'],
.form-group input[type='radio'] {
  margin-right: 20px;
}
.form-group.filled-fields {
  margin-bottom: 40px;
}
@media only screen and (max-width: 1100px) {
  .tpla_page {
    width: 100vw;
    height: 90vh;
    margin-left: 1vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .tpla_filter {
    padding: 5px;
    border: 1px solid #666;
    width: 95vw;
    overflow-y: auto;
    height: 30vh;
  }

  .filters-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    margin-bottom: 10vh;
  }

  .tpla_list {
    overflow-y: auto;
    width: 95vw;
    height: 60vh;
  }

  .filter {
    margin-top: 3vh;
    margin-left: 3vh;
    margin-right: 3vh;
  }

  .filter-buttons {
    position: fixed;
    display: flex;
    margin-top: 26vh;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 94vw;
  }

  .filter-button {
    width: 50%;
    height: 30px;
    border-color: #2e6da4;
    background-color: #2e6da4;
    color: white;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
  }

  .tpla_ids {
    order: 0;
  }

  .user_ids {
    order: 1;
  }

  .user_name {
    order: 2;
  }

  .phone_numbers {
    order: 3;
  }

  .location {
    order: 4;
  }

  .loan_amount {
    order: 5;
  }

  .monthly_income {
    order: 6;
  }

  .date {
    order: 7;
  }

  .state {
    order: 8;
  }

  .filled_fields {
    order: 9;
  }

  .checkboxes {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
  }

  .tpla-chart-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 100vw;
  }
}

@media only screen and (min-width: 1100px) {
  .tpla_filter {
    padding: 5px;
    border: 1px solid #666;
    margin-right: 1vw;
    width: 17vw;
    float: left;
    overflow: auto;
    height: 95vh;
  }

  .tpla_list {
    overflow-x: hidden;
    overflow-y: auto;
    float: right;
    width: 77vw;
    height: 95vh;
  }

  .filters-container {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-evenly;
  }

  .filter {
    margin-top: 3vh;
  }

  .filter-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    width: 15vw;
  }

  .filter-button {
    width: 50%;
    height: 30px;
    border-color: #2e6da4;
    background-color: #2e6da4;
    color: white;
    border-radius: 4px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
  }

  .tpla-chart-container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
  }

  .tpla-chart-item {
    width: 25vw;
    height: 20vh;
  }

  .checkboxes {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
  }

  .checkboxitems {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
  }

  .spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95vh;
    width: 77vw;
  }
}
