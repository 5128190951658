.chart-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.item {
  margin-top: 10vh;
  width: 40vw;
  height: 40vh;
}
